import React from 'react';

export const demos = {
  'button-demo-ghost': {
    component: React.lazy(() => import( /* webpackChunkName: "Button__demos" */ '/Users/onlystar/学习/文档/onlyxhb.github.io/src/Button/demos/ghost.tsx?techStack=react')),
    asset: {
  "type": "BLOCK",
  "id": "button-demo-ghost",
  "refAtomIds": [
    "Button"
  ],
  "dependencies": {
    "index.tsx": {
      "type": "FILE",
      "value": require('-!/Users/onlystar/学习/文档/onlyxhb.github.io/src/Button/demos/ghost.tsx?dumi-raw').default
    },
    "react": {
      "type": "NPM",
      "value": "18.1.0"
    },
    "onlyxhb.github.io": {
      "type": "NPM",
      "value": "1.0.0"
    }
  },
  "description": "ghost variant type button",
  "title": "ghost"
}
  },
  'button-demo-primary': {
    component: React.lazy(() => import( /* webpackChunkName: "Button__demos" */ '/Users/onlystar/学习/文档/onlyxhb.github.io/src/Button/demos/primary.tsx?techStack=react')),
    asset: {
  "type": "BLOCK",
  "id": "button-demo-primary",
  "refAtomIds": [
    "Button"
  ],
  "dependencies": {
    "index.tsx": {
      "type": "FILE",
      "value": require('-!/Users/onlystar/学习/文档/onlyxhb.github.io/src/Button/demos/primary.tsx?dumi-raw').default
    },
    "react": {
      "type": "NPM",
      "value": "18.1.0"
    },
    "onlyxhb.github.io": {
      "type": "NPM",
      "value": "1.0.0"
    }
  },
  "description": "primary variant type button",
  "title": "primary"
}
  },
};
export const frontmatter = {"title":"Button 按钮","toc":"content","filename":"src/Button/index.md","atomId":"Button","nav":{"title":"组件","order":2},"description":"按钮组件用于触发一个动作或事件，如提交一个表单、打开一个对话框、取消一个动作或执行一个删除操作。"};
export const toc = [{"id":"button-按钮","depth":1,"title":"Button 按钮"},{"id":"引用","depth":2,"title":"引用"},{"id":"使用","depth":2,"title":"使用"},{"id":"button-demo-ghost","depth":3,"title":"ghost"},{"id":"button-demo-primary","depth":3,"title":"primary"},{"id":"参数","depth":2,"title":"参数"}];
export const texts = [{"value":"按钮组件用于触发一个动作或事件，如提交一个表单、打开一个对话框、取消一个动作或执行一个删除操作。","paraId":0,"tocIndex":0},{"value":"import { Button } from 'example';\n","paraId":1,"tocIndex":1}];
